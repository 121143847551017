<script lang="ts" setup>
const router = useRouter()

const props = withDefaults(
  defineProps<{
    description: string
    icon: string
    path?: string | null
    title: string
  }>(),
  { path: null }
)

async function goToPath (): Promise<void> {
  const { path } = props

  if (!path) {
    return
  }

  await router.push(path)
}
</script>

<template>
  <div class="advantage" :class="{ 'has-path': path }" @click="goToPath">
    <div class="advantage-inner">
      <SvgIcon :name="icon" />

      <div>
        <div class="title mb4">
          {{ title }}
        </div>
        <p class="description">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.advantage {
  &.has-path {
    cursor: pointer;
  }

  .advantage-inner {
    background: white;
    border: 1px solid $main-green-color;
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    padding: 16px;

    svg {
      color: $main-green-color;
      font-size: 32px;
      margin-right: 8px;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
    }

    .description {
      font-size: 14px;
    }

    .title,
    .description {
      line-height: 20px;
    }
  }

  a {
    color: $main-green-color;
    text-decoration: underline;

    &:hover {
      color: $main-blue-color;
    }
  }
}
</style>
